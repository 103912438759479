<template lang="pug">
.brand-page-wrap
  link(rel="stylesheet" :href="`${frontendUrl}/assets/css/om.base.css?v=${baseCssVersion}`")
  .d-flex
    side-nav(v-if="showNav")
    .brand-page-wrap-content(:class="showNav ? 'brand-page-wrap-content-margin-left' : ''")
      .brand-onboarding-logout(
        v-if="onOnboardingRoute && !isMobile && !onOnboardingTemplateChooser && !inWizard && !onAgencyOnboarding && !onCodeInsertOnboarding"
      )
        a.brand-link(href="javascript:void(0)" @click="logout") {{ $t('menu.signOut') }}
      transition(name="fade")
        agency-impersonate(v-if="isAgencyImpersonate && !onEditor")
      mobile-header(v-if="showMobileHeader")
      editor-header(v-if="showEditorHeader")
      NotificationBar(v-if="showNotificationBar")

      om-editor-color-picker
      coupon-settings
      smart-tag-modal
      money-format

      add-theme-color(v-if="isTemplateEditorMode")

      lucky-wheel-settings(v-if="$store.state.showFormManager.lumiraSettings")
      scratch-card-settings(v-if="$store.state.showFormManager.scratchCardSettings")
      scratch-card-test(v-if="$store.state.showFormManager.scratchCardTest")
      custom-html-settings(v-if="$store.state.showFormManager.customHTMLSettings")
      missing-custom-fields(v-if="$store.state.showFormManager.missingCustomFields")
      pick-a-present-settings(v-if="$store.state.showFormManager.pickAPresentSettings")
      pick-a-present-test(v-if="$store.state.showFormManager.pickAPresentTest")

      form-chooser(v-if="$store.state.showFormManager.firstStep")
      form-chooser-edit(v-if="$store.state.showFormManager.thirdStep")
      form-chooser-new(v-if="$store.state.showFormManager.secondStep")

      transition(v-if="!$route.path.includes('templates')" name="fade" mode="out-in")
        router-view(:key="$route.fullPath")
      router-view(v-else)
      scrollTop(:class="{ 'brand-scroll-top-show': showScrollTop }" @click.native="scrollTop")
      om-notification(classes="brand-notification" position="bottom right" width="auto")
      upgrade-plan-modal
      downgrade-modal(
        name="global-downgrade-modal"
        :toPlan="downgradeToPlan"
        :fromPlan="downgradeFromPlan"
        :closable="false"
        @cancel="cancelDowngrade"
        @downgrade="downgrade"
      )
      payment-problem-modal(name="braintree-payment-problem-modal" type="braintree")
      payment-problem-modal(name="bank-transfer-payment-problem-modal" type="bankTransfer")
      payment-problem-modal(name="shopify-payment-problem-modal" type="shopify")
      lead-sync-modal
      agency-contact-details-modal
      portal-target(name="root" multiple)
      v-dialog(width="100%")
</template>

<script>
  import SimpleHeader from '@/components/SimpleHeader.vue';
  import LoadingTransition from '@/components/LoadingTransition.vue';
  import ScrollTop from '@/components/ScrollTop.vue';
  import UpgradePlanModal from '@/components/Modals/UpgradePlan';
  import DowngradeModal from '@/components/Modals/DowngradeModal.vue';
  import PaymentProblemModal from '@/components/Modals/PaymentProblemModal.vue';

  import { createNamespacedHelpers, mapActions, mapGetters, mapState } from 'vuex';
  import SideSubNav from '@/components/SideSubNav.vue';
  import LeadSyncModal from '@/components/FailedIntegration/LeadSyncModal.vue';
  import AgencyContactDetailsModal from '@/components/Onboarding/AgencyContactDetailsModal.vue';
  import {
    getAccountIdFromCookie,
    getPaymentProblemCookie,
    mobileWidth,
    setPaymentProblemCookie,
  } from '@/util'; // ms
  import { showUnfinishedOnboarding } from '@/router/onboarding-utils';
  import { get } from 'lodash-es';
  import { checkShopsWithMissingScope } from '@/utils/shopify';
  import { applyDesignSystemFunctionsToWindow } from '@/utils/designSystemHighlight';
  import 'driver.js/dist/driver.min.css';
  import templateCreate from '@/mixins/templateCreate';
  import appEmbedAlert from '@/mixins/appEmbedAlert';
  import integrationResyncPoll from '@/mixins/integrationResyncPoll';
  import runtimeConfig from '@/config/runtime';
  import { initHubSpotChat } from '@/services/hubSpot';
  import { track } from '@/services/xray';

  const frontendUrl = runtimeConfig.VUE_APP_FRONTEND;

  const { mapActions: paymentActions, mapGetters: paymentGetters } =
    createNamespacedHelpers('payment');
  const HEARTBEAT_INTERVAL = 20000;

  const MANUAL_ADMIN_LOADER_ROUTES = [
    'new_campaign',
    'templates',
    'your-themes-collection',
    'your-themes-templates',
    'themes-collection',
    'seasonal-collection',
    'seasonal-templates',
    'custom-templates',
    'dashboard',
    'subscribers',
    'campaigns',
  ];

  export default {
    components: {
      SimpleHeader,
      LoadingTransition,
      ScrollTop,
      UpgradePlanModal,
      SideSubNav,
      DowngradeModal,
      PaymentProblemModal,
      LeadSyncModal,
      AgencyContactDetailsModal,

      SideNav: () => import('@/components/SideNav.vue'),
      AgencyImpersonate: () => import('@/components/AgencyImpersonate'),
      MobileHeader: () => import('@/components/MobileHeader.vue'),
      EditorHeader: () => import('@/editor/components/EditorHeader.vue'),
      NotificationBar: () => import('@/components/NotificationBar.vue'),
    },

    mixins: [templateCreate, appEmbedAlert, integrationResyncPoll],

    data() {
      return {
        isSlim: false,
        showScrollTop: false,
        adminLoaderStartTime: null,
        campaignBulkOperation: null,
        windowWidth: null,
        baseCssVersion: Date.now(),
        onEditor: false,
        broadcastChannel: null,
        frontendUrl,
      };
    },
    computed: {
      ...mapState(['account', 'loggedIn', 'showAdminLoader']),
      ...mapGetters([
        'getPendingShopifyInstall',
        'onboardingFinished',
        'isDowngradePending',
        'isAgencyImpersonate',
        'isMobile',
        'hasActiveShopify',
        'isTemplateEditorMode',
      ]),
      ...paymentGetters([
        'hasBankTransferPaymentProblem',
        'hasBraintreePaymentProblem',
        'hasShopifyPaymentProblem',
      ]),
      noLoginNeeded() {
        return this.$route.meta.permission === undefined;
      },
      onLoginRoute() {
        return this.$route.name === 'login';
      },
      onRegistrationRoute() {
        return this.$route.name === 'registration';
      },
      onAgencyOnboarding() {
        if (!this.$route.name) return false;
        return this.$route.name.includes('agency');
      },
      inWizard() {
        if (!this.$route.name) return false;
        return this.$route.name.includes('wizard');
      },
      onOnboardingTemplateChooser() {
        return this.$route.name === 'new_campaign_onboarding';
      },
      onCodeInsertOnboarding() {
        return this.onOnboardingRoute && (this.$route?.name || '').includes('code-insert');
      },
      showMenu() {
        return (
          (!this.noLoginNeeded &&
            !this.onLoginRoute &&
            !this.onRegistrationRoute &&
            !this.onOAuthLoginRoute &&
            !this.onEditor &&
            !this.onOnboardingRoute) ||
          this.onCodeInsertOnboarding
        );
      },
      showNav() {
        if (this.mobileWidth) {
          return false;
        }

        return !this.$route.meta.hideNav && this.showMenu;
      },
      showMobileHeader() {
        return this.mobileWidth && this.showMenu;
      },
      showNotificationBar() {
        const isNotOnLoginRoute = !this.onOAuthLoginRoute;
        const isNotOnSwitchAccount = !this.onSwitchAccount;
        const isNotOnConnectShopify = !this.onConnectShopify;
        const isNotOnCodeInsert = !this.onCodeInsertRoute;
        const isNotMobile = !this.isMobile;

        return (
          this.isLoggedIn() &&
          isNotMobile &&
          isNotOnLoginRoute &&
          isNotOnSwitchAccount &&
          isNotOnConnectShopify &&
          isNotOnCodeInsert &&
          !this.onOnboardingRoute
        );
      },
      onDashboard() {
        return this.$route.name === 'dashboard';
      },
      showEditorHeader() {
        return (
          this.onEditor ||
          this.$route.name === 'campaign_settings' ||
          this.$route.name === 'dc_goal_create'
        );
      },
      mobileWidth() {
        if (!this.windowWidth) {
          this.setWidth();
        }
        return mobileWidth(this.windowWidth);
      },
      downgradeFromPlan() {
        const currentPlan = this.$store.getters.currentPlan;

        if (this.isDowngradePending) {
          return get(this.$store.state, 'account.settings.downgrade.from', currentPlan);
        }

        return currentPlan;
      },
      downgradeToPlan() {
        if (this.isDowngradePending) {
          return get(this.$store.state, 'account.settings.downgrade.to', null);
        }

        return null;
      },
    },

    watch: {
      /*
       * This function tracks the duration for which the admin loader is shown.
       * If the duration exceeds the SLOW_THRESHOLD, it sends the event to heap.
       */
      showAdminLoader(newV, oldV) {
        const SLOW_THRESHOLD = 2000;
        if (newV) {
          this.adminLoaderStartTime = Date.now();
        } else if (oldV && !newV) {
          const duration = Date.now() - this.adminLoaderStartTime;
          console.log('Admin loader was shown for', duration, 'ms');
          if (duration >= SLOW_THRESHOLD) {
            track('slowAdminLoader', { duration });
          }
        }
      },
      showNav(v) {
        if (!v) {
          document.querySelector('html').classList.add('brand-side-nav-hidden');
        } else {
          document.querySelector('html').classList.remove('brand-side-nav-hidden');
        }
      },
      async $route(_, previousRoute) {
        if (this.isLoggedIn() && !this.$route.meta.withoutUserId) {
          const fullReFetch = this.$route.params?.userId !== previousRoute.params?.userId;
          await this.reFetchAccount(fullReFetch);
          this.showPaymentProblemModal();
        }
        this.onEditor = ['editor', 'variant_edit', 'template_editor'].includes(this.$route.name);
        if (
          !this.onEditor &&
          !this.isFromConnectPage(previousRoute) &&
          !MANUAL_ADMIN_LOADER_ROUTES.includes(this.$route.name)
        ) {
          this.$store.commit('showAdminLoader', false);
        }

        if (this.isLoggedIn()) {
          const { action, payment, charge_id: chargeId } = this.$route.query;
          const planValidation = !(payment === 'shopify' && chargeId && action === 'return2');
          this.$store.dispatch('payment/loadBilling', { planValidation });
          if (previousRoute && previousRoute.name === 'login') {
            initHubSpotChat(this.$apollo);
          }
        }
      },
      isDowngradePending(pending) {
        if (pending) {
          // this.$modal.show('global-downgrade-modal') // TEMP DISABLED
        }
      },
      hasActiveShopify() {
        if (this.hasActiveShopify) {
          checkShopsWithMissingScope();
        }
      },
    },

    async mounted() {
      this.broadcastMountedEvent();
      this.updateDeviceType();
      window.addEventListener('scroll', this.scrollHandler);
      this.heartbeat();
      setInterval(() => {
        this.heartbeat();
      }, HEARTBEAT_INTERVAL);

      this.$store.state.loadingTransitionRef = document.querySelector(
        'iframe.brand-loading-transition',
      );

      this.$bus.$on('pollAccount', this.pollAccount);

      if (this.onSwitchAccount) {
        this.$store.commit('showAdminLoader', false);
      }

      if (this.isLoggedIn()) {
        await this.reFetchAccount();
        if (this.$route.name !== 'oauth_login' && !this.getPendingShopifyInstall) {
          await showUnfinishedOnboarding();
        }
        initHubSpotChat(this.$apollo);
      }

      window.addEventListener('resize', this.setWidth);
      window.addEventListener('resize', this.updateDeviceType);
      window.addEventListener('orientationchange', this.setWidth);
      applyDesignSystemFunctionsToWindow();
      window.OMProductTour = {
        getDriver: () => {
          return this.$store.state.productTour.driver;
        },
        fn: (action) => {
          return this.$store.dispatch(`productTour/${action}`);
        },
      };
      this.$bus.$on('reportEditorEvent', this.reportEditorEvent);
    },

    beforeDestroy() {
      this.$bus.$off('pollAccount', this.pollAccount);
      this.$bus.$off('reportEditorEvent', this.reportEditorEvent);
      window.removeEventListener('scroll', this.scrollHandler);
      window.removeEventListener('resize', this.setWidth);
      window.removeEventListener('resize', this.updateDeviceType);
      window.removeEventListener('orientationchange', this.setWidth);
    },

    methods: {
      ...mapActions(['fetchAccount', 'fetchInsertCodeStatus', 'updateDeviceType', 'logout']),
      ...paymentActions([
        'loadAccount',
        'fetchFlexiPayDetail',
        'loadDeclinedPaymentDetail',
        'initPlanListService',
        'initCouponService',
      ]),
      ...mapActions('analytics', ['reportEditorEvent']),

      initBroadcastChannel() {
        if (typeof window.BroadcastChannel === 'undefined' || this.broadcastChannel) return;
        this.broadcastChannel = new BroadcastChannel('optimonk-admin');
        this.broadcastChannel.addEventListener('message', this.handleBroadcastChannelMessage);
      },
      async handleBroadcastChannelMessage(e) {
        if (e.data && e.data === 'check_id') {
          await this.handleWrongUserId();
        }
      },
      async handleWrongUserId() {
        if (!this.isLoggedIn() || this.$route.meta.withoutUserId) return;
        try {
          await this.reFetchAccount();
          const userId = getAccountIdFromCookie();

          const { route } = this.$router.resolve({
            ...this.$route,
            params: { ...this.$route.params, userId },
          });
          const fullRoutePath = this.$route.fullPath;
          if (route && !fullRoutePath.startsWith(`/${userId}/`)) {
            const name = this.onOnboardingRoute() ? 'onboarding' : 'dashboard';
            await this.$router.replace({ name, params: { userId } });
          }
        } catch (e) {
          window.location.href = `${window.location.protocol}//${window.location.host}`;
        }
      },
      broadcastMountedEvent() {
        if (!this.broadcastChannel) this.initBroadcastChannel();
        this.broadcastChannel?.postMessage('check_id');
      },
      scrollTop() {
        this.$scrollTo('body', 0, { easing: 'ease', cancelable: false });
      },
      heartbeat() {
        if (this.isLoggedIn()) {
          if (this.$route.name !== 'switch_account') {
            this.fetchInsertCodeStatus();
          }
        }
      },

      pollAccount() {
        const accountPollInterval = setInterval(() => {
          this.reFetchAccount();
        }, 2000);

        setTimeout(() => {
          clearInterval(accountPollInterval);
        }, 10000);
      },
      setWidth() {
        this.windowWidth = window.innerWidth;
      },
      downgrade() {},
      cancelDowngrade() {},
      reFetchAccount(fullReFetch = true) {
        return Promise.all([
          this.fetchAccount(),
          this.loadAccount(),
          ...(fullReFetch
            ? [
                this.fetchFlexiPayDetail(),
                this.loadDeclinedPaymentDetail(),
                this.initPaymentServices(),
              ]
            : []),
        ]);
      },

      initPaymentServices() {
        this.initPlanListService();
        this.initCouponService();
        return Promise.resolve();
      },

      showPaymentProblemModal() {
        if (getPaymentProblemCookie() || this.onEditor) {
          return;
        }

        if (this.hasBraintreePaymentProblem) {
          setPaymentProblemCookie();
          this.$modal.show('braintree-payment-problem-modal');
        }
        if (this.hasBankTransferPaymentProblem) {
          setPaymentProblemCookie();
          this.$modal.show('bank-transfer-payment-problem-modal');
        }
        if (this.hasShopifyPaymentProblem) {
          setPaymentProblemCookie();
          this.$modal.show('shopify-payment-problem-modal');
        }
      },
      isFromConnectPage(prevRoute) {
        return prevRoute && prevRoute.name === 'connect_shop';
      },
    },
  };
</script>

<style lang="sass">
  @import './sass/main.sass'
  @import './sass/components/_checkbox.sass'
  @import './sass/pages/_campaign_settings.sass'
  @import './sass/pages/_auth.sass'
</style>
<style lang="sass" src="@/editor/sass/sharedWithAdmin/main.sass" />
